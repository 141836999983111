<template>
  <Sidebar
    :visible="visible"
    @update:visible="onToggle"
    class="w-full sm:w-40rem"
    position="right"
    :header="header"
    :pt="{
      content: {
        class: 'flex flex-column'
      }
    }"
    blockScroll
    :dismissable="false"
  >
    <div class="flex flex-column flex-grow-1 overflow-y-auto overflow-x-hidden">
      <template v-if="activeStep === 1">
        <VeeForm
          v-slot="{ handleSubmit }"
          as="div"
          :validationSchema="stepOneSchema"
          @invalidSubmit="onInvalidSubmit"
        >
          <form
            id="add-user-form"
            @submit.prevent="handleSubmit($event, onSubmitStepOne)"
          >
            <div class="grid">
              <div class="col-12 sm:col-6">
                <BaseInput
                  v-model="stepOneForm.firstName"
                  fieldId="firstName"
                  fieldName="firstName"
                  fieldLabel="First Name"
                />
              </div>
              <div class="col-12 sm:col-6">
                <BaseInput
                  v-model="stepOneForm.lastName"
                  fieldId="lastName"
                  fieldName="lastName"
                  fieldLabel="Last Name"
                />
              </div>
              <!-- <div class="col-12 sm:col-8">
                <BaseInput
                  v-model="stepOneForm.email"
                  fieldId="email"
                  fieldName="email"
                  fieldLabel="Email (optional)"
                  type="email"
                />
              </div> -->
              <div class="col-12 sm:col-6">
                <BaseDropdown
                  v-model="stepOneForm.accent"
                  fieldId="accent"
                  fieldName="accent"
                  fieldLabel="Accent"
                  placeholder="Choose an accent"
                  :options="ACCENT_OPTIONS"
                  filter
                >
                  <template #value="slotProps">
                    <div class="flex align-items-center">
                      {{ slotProps.value || slotProps.placeholder }}
                      <Chip
                        v-if="accentIsBeta"
                        class="ml-1 text-xs py-0 bg-indigo-100"
                        label="Beta"
                      />
                    </div>
                  </template>
                  <template #option="slotProps">
                    {{ slotProps.option.label }}
                    <Chip
                      v-if="slotProps.option.isBeta"
                      class="ml-2 text-xs py-0 bg-indigo-100"
                      label="Beta"
                    />
                  </template>
                </BaseDropdown>
              </div>
              <div class="col-12 sm:col-6">
                <BaseDropdown
                  v-model="stepOneForm.gender"
                  fieldId="gender"
                  fieldName="gender"
                  fieldLabel="Gender"
                  placeholder="Choose a gender"
                  :options="VOICE_GENDER_OPTIONS"
                />
              </div>
              <div class="col-12">
                <BaseTextarea
                  v-model="stepOneForm.description"
                  fieldId="publicBio"
                  fieldName="description"
                  fieldLabel="Host Professional Bio"
                  :helperText="PUBLIC_BIO_HELPER_TEXT"
                  :characterLimit="PUBLIC_BIO_CHARACTER_LIMIT"
                />
              </div>
              <div class="col-12">
                <BaseTextarea
                  v-model="stepOneForm.personalBio"
                  fieldId="personalBio"
                  fieldName="personalBio"
                  fieldLabel="Host Personal Bio"
                  :characterLimit="PERSONAL_BIO_CHARACTER_LIMIT"
                >
                  <template #helperText>
                    <small
                      id="personalBio-help"
                      v-html="PERSONAL_BIO_HELPER_MARKUP"
                    />
                  </template>
                </BaseTextarea>
              </div>
            </div>
          </form>
        </VeeForm>
      </template>
      <template v-if="activeStep === 2">
        <VeeForm
          v-slot="{ handleSubmit }"
          as="div"
          :validationSchema="stepTwoSchema"
          @invalidSubmit="onInvalidSubmit"
        >
          <form
            id="add-user-form"
            @submit.prevent="handleSubmit($event, onSubmitStepTwo)"
          >
            <BaseFileUpload
              v-model="stepTwoForm.selectedFiles"
              fieldId="selectedFiles"
              fieldName="selectedFiles"
              helperText="
                We recommend including 1 to 3 samples that total to at
                least 90 seconds to provide the most accurate result.
                The samples should be as clear as possible with no background noise.
                If available, use existing ads that you have recorded."
              accept="audio/mpeg"
              :multiple="true"
              dragText="Drag your mp3 file(s) here"
            />
          </form>
        </VeeForm>
      </template>
      <template v-if="activeStep === 3">
        <p>
          This is an example ad based on your Voiceprint Eko.
          Adjust the settings below and click "Update" to fine-tune your voiceprint.
        </p>

        <form
          id="add-user-form"
          @submit.prevent="onSubmitStepThree"
        >
          <VoiceprintCard
            :isLoading="voiceprintIsLoading"
            :src="voiceprint"
          >
            <template #noVoiceprint>
              No Voiceprint Eko found.
            </template>
          </VoiceprintCard>
        </form>

        <Card class="bg-dark-grey mt-3 text-white">
          <template #title>
            Settings
          </template>
          <template #content>
            <Button
              class="w-full justify-content-center"
              label="Update"
              :loading="isUpdatingSettings"
              @click="onUpdateSettings"
            />
            <div class="grid">
              <div class="col-12 sm:col-6 pb-0 sm:pb-2">
                <VoiceprintSlider
                  v-for="item in leftSliders"
                  :key="item.name"
                  v-model="stepThreeForm[item.formField]"
                  :name="item.name"
                  :formId="item.formId"
                  :tooltip="item.tooltip"
                  :labelUnit="item.labelUnit"
                  :min="item.min"
                  :max="item.max"
                  :step="item.step"
                  :transformFunction="item.transformFunction"
                />
              </div>
              <div class="col-12 sm:col-6 pt-0 sm:pt-2">
                <VoiceprintSlider
                  v-for="item in rightSliders"
                  :key="item.name"
                  v-model="stepThreeForm[item.formField]"
                  :name="item.name"
                  :formId="item.formId"
                  :tooltip="item.tooltip"
                  :labelUnit="item.labelUnit"
                  :min="item.min"
                  :max="item.max"
                  :step="item.step"
                  :transformFunction="item.transformFunction"
                />
              </div>
            </div>
          </template>
        </Card>
      </template>
    </div>

    <div class="flex justify-content-between pt-2">
      <Button
        label="Cancel"
        text
        plain
        :disabled="isSubmitting"
        @click="() => onToggle(false)"
      />
      <div>
        <Button
          v-if="activeStep !== 1"
          text
          plain
          label="Previous"
          :disabled="isSubmitting"
          @click="onClickPrevious"
        />
        <Button
          class="ml-2"
          :label="nextButtonText"
          type="submit"
          form="add-user-form"
          :loading="isSubmitting"
        />
      </div>
    </div>
  </Sidebar>
</template>

<script>
import { mapStores } from 'pinia';
import { useMyUserStore, useRolesStore, useUsersStore } from '@/stores';
import { object, string, array } from 'yup';
import {
  ACCENT_OPTIONS,
  PERSONAL_BIO_CHARACTER_LIMIT,
  PUBLIC_BIO_CHARACTER_LIMIT,
  DEFAULT_VOICE_STABILITY,
  DEFAULT_VOICE_SIMILARITY_BOOST,
  DEFAULT_VOICE_STYLE_EXAGERATION,
  DEFAULT_VOICE_PITCH_CHANGE,
  DEFAULT_VOICE_SPEED_CHANGE,
  DEFAULT_VOICEPRINT_SAMPLE_TEXT,
  VOICE_GENDER_OPTIONS,
} from '@/constants';
import { parseMessageFromError } from '@/utils/errors';
import { INVALID_FORM_SUBMISSION_MESSAGE, PUBLIC_BIO_HELPER_TEXT, PERSONAL_BIO_HELPER_MARKUP } from '@/utils/messages';
import * as api from '@/api';
import VoiceprintCard from '@/components/voiceprintCard';
import VoiceprintSlider from '@/components/voiceprintWizard/components/voiceprintSlider';

const SLIDERS = [
  {
    name: 'Charisma',
    formId: 'slider-charisma',
    formField: 'voiceStability',
    tooltip: 'This determines the emotional range of the voice. Increasing the slider, adds a broader range of emotion.',
    labelUnit: '%',
  },
  {
    name: 'Vocal Clarity',
    formId: 'slider-vocal-clarity',
    formField: 'voiceSimilarityBoost',
    tooltip: 'This determines how close the voice should match to the original audio samples.',
    labelUnit: '%',
  },
  {
    name: 'Style Exaggeration',
    formId: 'slider-style-exaggeration',
    formField: 'styleExaggeration',
    tooltip: 'This exaggerates the style of the original audio samples. 0 means no exaggeration.',
    labelUnit: '%',
  },
  {
    name: 'Pitch',
    formId: 'slider-pitch-change',
    formField: 'pitchChange',
    min: 90,
    max: 110,
    step: 0.1,
    // transform values values of range 90 - 110 to -10 - 10
    transformFunction: (value) => Math.round((value - 100) * 100) / 100,
  },
  {
    name: 'Speed',
    formId: 'slider-speed-change',
    formField: 'speedChange',
    min: 0.9,
    max: 1.1,
    step: 0.01,
  },
];

const generateEmptyStepOneForm = () => ({
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  accent: null,
  gender: null,
  description: '',
  personalBio: '',
});
const generateEmptyStepTwoForm = () => ({
  selectedFiles: [],
});
const generateEmptyStepThreeForm = () => ({
  voiceStability: Math.round((1 - DEFAULT_VOICE_STABILITY) * 100),
  voiceSimilarityBoost: Math.round(DEFAULT_VOICE_SIMILARITY_BOOST * 100),
  styleExaggeration: Math.round(DEFAULT_VOICE_STYLE_EXAGERATION * 100),
  pitchChange: DEFAULT_VOICE_PITCH_CHANGE,
  speedChange: DEFAULT_VOICE_SPEED_CHANGE,
});

export default {
  components: {
    VoiceprintCard,
    VoiceprintSlider,
  },
  props: {
    visible: Boolean,
  },
  computed: {
    ...mapStores(useMyUserStore, useRolesStore, useUsersStore),
    header() {
      switch (this.activeStep) {
        case 1:
          return 'Setup Profile';
        case 2:
          return 'Upload Voice samples';
        case 3:
          return 'Tune Voiceprint';
        default:
          return 'Add User';
      }
    },
    nextButtonText() {
      switch (this.activeStep) {
        case 1:
          return 'Next';
        case 2:
          return 'Next';
        case 3:
          return 'Submit';
        default:
          return 'Next';
      }
    },
    accentIsBeta() {
      if (!this.stepOneForm.accent) return false;

      const chosenAccent = ACCENT_OPTIONS.find((item) => item.value === this.stepOneForm.accent);

      return chosenAccent && chosenAccent.isBeta === true;
    },
  },
  data() {
    return {
      PUBLIC_BIO_HELPER_TEXT,
      PERSONAL_BIO_HELPER_MARKUP,
      PUBLIC_BIO_CHARACTER_LIMIT,
      PERSONAL_BIO_CHARACTER_LIMIT,
      ACCENT_OPTIONS,
      VOICE_GENDER_OPTIONS,
      leftSliders: SLIDERS.slice(0, 3),
      rightSliders: SLIDERS.slice(3),
      stepOneSchema: object({
        firstName: string().required('First name is required'),
        lastName: string().required('Last name is required'),
        // TODO - add email & password validations
        accent: string().required('Accent is required'),
        gender: string().required('Gender is required'),
        description: string().nullable().max(PUBLIC_BIO_CHARACTER_LIMIT, `Host professional bio can be a max of ${PUBLIC_BIO_CHARACTER_LIMIT} characters.`),
        personalBio: string().nullable().max(PERSONAL_BIO_CHARACTER_LIMIT, `Host personal bio can be a max of ${PERSONAL_BIO_CHARACTER_LIMIT} characters.`),
      }),
      stepOneForm: generateEmptyStepOneForm(),
      stepTwoSchema: object({
        selectedFiles: array().min(1, 'Must upload at least 1 file').required(),
      }),
      stepTwoForm: generateEmptyStepTwoForm(),
      stepThreeForm: generateEmptyStepThreeForm(),
      isSubmitting: false,
      activeStep: 1,
      // current user id
      userId: null,
      // used in case user goes to step 3, back to step 2, then back to step 3
      filesUploaded: [],
      voiceprint: null,
      isUpdatingSettings: false,
      voiceprintIsLoading: false,
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler() {
        this.activeStep = 1;
        this.userId = null;
        this.stepOneForm = generateEmptyStepOneForm();
        this.stepTwoForm = generateEmptyStepTwoForm();
        this.stepThreeForm = generateEmptyStepThreeForm();
      },
    },
  },
  methods: {
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    onToggle(visible) {
      if (this.isSubmitting) return;

      this.$emit('update:visible', visible);
    },
    onClickPrevious() {
      this.activeStep = this.activeStep === 1
        ? this.activeStep
        : this.activeStep - 1;
    },
    onSubmitStepOne() {
      this.activeStep = 2;
    },
    async onSubmitStepTwo() {
      try {
        this.isSubmitting = true;

        if (!this.userId) {
          const createUserRes = await api.createUser({
            firstName: this.stepOneForm.firstName,
            lastName: this.stepOneForm.lastName,
            roleId: this.rolesStore.hostAdminRole.id,
            description: this.stepOneForm.description,
            personalBio: this.stepOneForm.personalBio,
            organizationId: this.myUserStore.myOrganization.id,
          });

          this.userId = createUserRes.id;
        } else {
          await api.updateUser({
            userId: this.userId,
            firstName: this.stepOneForm.firstName,
            lastName: this.stepOneForm.lastName,
            description: this.stepOneForm.description,
            personalBio: this.stepOneForm.personalBio,
          });
        }

        const usersRes = await api.readUsers({ userId: this.userId });
        const user = usersRes.find((item) => Number(item.id) === Number(this.userId));

        const newFiles = this.stepTwoForm.selectedFiles
          .filter((item) => !this.filesUploaded.includes(item));

        if (!user.voice_id && newFiles.length > 0) {
          await api.updateUserVoiceSettings({
            userId: this.userId,
            voiceStability: Math.round((1 - (this.stepThreeForm.voiceStability / 100)) * 100) / 100,
            voiceSimilarityBoost: this.stepThreeForm.voiceSimilarityBoost / 100,
            styleExaggeration: this.stepThreeForm.styleExaggeration / 100,
            pitchChange: this.stepThreeForm.pitchChange,
            speedChange: this.stepThreeForm.speedChange,
          });
          await api.createUserVoicePrint({
            userId: this.userId,
            files: newFiles,
            accent: this.stepOneForm.accent,
            gender: this.stepOneForm.gender,
          });
        } else if (user.voice_id) {
          if (newFiles.length > 0) {
            await api.createAudioSamples(this.userId, newFiles);
          }
          const voiceSamplesRes = await api.readUserAudioSamples(this.userId);

          await api.updateUserVoiceSettings({
            userId: this.userId,
            voiceStability: Math.round((1 - (this.stepThreeForm.voiceStability / 100)) * 100) / 100,
            voiceSimilarityBoost: this.stepThreeForm.voiceSimilarityBoost / 100,
            styleExaggeration: this.stepThreeForm.styleExaggeration / 100,
            pitchChange: this.stepThreeForm.pitchChange,
            speedChange: this.stepThreeForm.speedChange,
          });

          await api.updateUserVoicePrint({
            userId: this.userId,
            sampleIds: voiceSamplesRes.map((item) => item.id),
            accent: this.stepOneForm.accent,
            gender: this.stepOneForm.gender,
          });
        }

        this.filesUploaded = [...this.filesUploaded, ...newFiles];

        await this.getVoicePrint();

        await this.usersStore.getUsers({
          organizationId: this.myUserStore.myOrganization.id,
        });

        this.activeStep = 3;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error submitting user.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isSubmitting = false;
      }
    },
    async getVoicePrint() {
      try {
        this.voiceprintIsLoading = true;

        const voiceprintRes = await api.createTextToSpeech({
          userId: this.userId,
          text: DEFAULT_VOICEPRINT_SAMPLE_TEXT,
        });
        this.voiceprint = voiceprintRes.s3_url;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error generating example voiceprint.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.voiceprintIsLoading = false;
      }
    },
    onSubmitStepThree() {
      this.$toast.add({
        severity: 'success',
        detail: 'Voiceprint Eko approved and saved.',
      });

      this.$emit('selectUserid', this.userId);
      this.$emit('update:visible', false);
    },
    async onUpdateSettings() {
      try {
        this.isUpdatingSettings = true;

        await api.updateUserVoiceSettings({
          userId: this.userId,
          voiceStability: Math.round((1 - (this.stepThreeForm.voiceStability / 100)) * 100) / 100,
          voiceSimilarityBoost: this.stepThreeForm.voiceSimilarityBoost / 100,
          styleExaggeration: this.stepThreeForm.styleExaggeration / 100,
          pitchChange: this.stepThreeForm.pitchChange,
          speedChange: this.stepThreeForm.speedChange,
        });

        await this.getVoicePrint();

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully updated voice settings',
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error updating settings.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isUpdatingSettings = false;
      }
    },
  },
};
</script>
