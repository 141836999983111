<template>
  <PageContainer maxWidth="1300px">
    <!-- Summary -->
    <Card>
      <template #content>
        <VeeForm
          v-slot="{ handleSubmit }"
          :validation-schema="schema"
          as="div"
          @invalidSubmit="onInvalidSubmit"
        >
          <form
            @submit.prevent="handleSubmit($event, onSubmitOrganizationInfo)"
          >
            <h2 class="mt-0 text-xl">
              Organization
            </h2>
            <div class="grid">
              <div class="col-12">
                <BaseInput
                  v-model="organizationForm.name"
                  fieldId="name"
                  fieldName="name"
                  fieldLabel="Name"
                />
              </div>
            </div>
            <div class="flex justify-content-end">
              <Button
                type="submit"
                :loading="organizationFormIsSubmitting"
                label="Save"
              />
            </div>
          </form>
        </VeeForm>
      </template>
    </Card>

    <Card class="mt-4">
      <template #content>
        <div class="grid">
          <div class="col-12 sm:col sm:flex-grow-1">
            <h2 class="mt-0 text-xl">
              Users
            </h2>
          </div>
          <div class="col-12 sm:col sm:flex-grow-0">
            <Button
              class="w-full justify-content-center"
              severity="success"
              @click="addUserDialogData.visible = true"
            >
              Invite
            </Button>
          </div>
        </div>

        <div class="mt-4">
          <DataTable
            :value="compiledUsers"
            dataKey="id"
          >
            <Column
              field="sanitizedEmail"
              header="Email"
            />
            <Column
              field="fname"
              header="First Name"
            />
            <Column
              field="lname"
              header="Last Name"
            />
            <Column
              field="role"
              header="Role"
            />
            <Column
              field="status"
              header="Status"
            >
              <template #body="slotProps">
                <Chip :class="slotProps.data.statusChipClass">{{ slotProps.data.status }}</Chip>
              </template>
            </Column>
            <Column
              class="w-1p"
            >
              <template #body="slotProps">
                <Button
                  icon="pi pi-ellipsis-v"
                  text
                  plain
                  aria-haspopup="true"
                  :aria-controls="`user-row-menu-${slotProps.data.id}`"
                  @click="(event) => this.$refs[`userRowMenu${slotProps.data.id}`].toggle(event)"
                />
                <Menu
                  :id="`user-row-menu-${slotProps.data.id}`"
                  class="right-align-menu"
                  :ref="`userRowMenu${slotProps.data.id}`"
                  popup
                  :model="userRowOptionsDictionary[slotProps.data.id]"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </Card>

    <Card class="mt-4">
      <template #content>
        <div class="grid">
          <div class="col-12 sm:col sm:flex-grow-1">
            <h2 class="mt-0 text-xl">
              Podcasts
            </h2>
          </div>
          <div class="col-12 sm:col sm:flex-grow-0">
            <Button
              class="w-full justify-content-center"
              severity="success"
              @click="addProgramDialogData.visible = true"
            >
              Add
            </Button>
          </div>
        </div>

        <div class="mt-4">
          <DataTable
            :value="compiledPrograms"
            dataKey="id"
          >
            <Column
              header="Name"
            >
              <template #body="slotProps">
                <div class="flex">
                  {{ slotProps.data.name }}
                  <template v-if="!!slotProps.data.spreaker_show_id">
                    <Chip
                      v-if="!!slotProps.data.email_confirmation_date"
                      class="ml-1 bg-green-400 text-white text-xs"
                    >
                      Verified
                    </Chip>
                    <!-- eslint-disable max-len -->
                    <Chip
                      v-else
                      class="ml-1 bg-yellow-100 text-yellow-600 text-xs"
                      v-tooltip.top="`We have sent the podcast host an email. They must validate the podcast before you can start receiving campaigns.`"
                    >
                      Not verified
                    </Chip>
                    <!-- eslint-enable max-len -->
                  </template>
                </div>
              </template>
            </Column>
            <Column
              header="Host"
            >
              <template #body="slotProps">
                {{
                  userToProgramDictionary[slotProps.data.id]
                    ? userToProgramDictionary[slotProps.data.id].fullName
                    : ''
                }}
                <template v-if="!slotProps.data.host_user_id">
                  <i
                    class="ml-1 pi pi-exclamation-triangle text-yellow-500"
                    :style="{
                      fontSize: '1.25em',
                    }"
                    v-tooltip.top="`You must add a host user before receiving ad campaigns.`"
                  />
                </template>
                  <template v-else-if="userToProgramDictionary[slotProps.data.id]
                  && !userToProgramDictionary[slotProps.data.id].voice_id">
                  <!-- eslint-disable max-len -->
                  <i
                    class="ml-1 pi pi-exclamation-triangle text-yellow-500"
                    :style="{
                      fontSize: '1.25em',
                    }"
                    v-tooltip.top="'The host user assigned to this podcast does not have a voiceprint. You must add a voiceprint to this user before the podcast can receive ad campaigns.'"
                  />
                  <!-- eslint-enable max-len -->
                  </template>
              </template>
            </Column>
            <Column
              class="w-1p"
            >
              <template #body="slotProps">
                <Button
                  icon="pi pi-ellipsis-v"
                  text
                  plain
                  aria-haspopup="true"
                  :aria-controls="`programs-row-menu-${slotProps.data.id}`"
                  @click="(event) => this.$refs[`programRowMenu${slotProps.data.id}`].toggle(event)"
                />
                <Menu
                  :id="`programs-row-menu-${slotProps.data.id}`"
                  class="right-align-menu"
                  :ref="`programRowMenu${slotProps.data.id}`"
                  popup
                  :model="programRowOptionsDictionary[slotProps.data.id]"
                />
              </template>
            </Column>
            <template #empty>No podcasts found</template>
          </DataTable>
        </div>
      </template>
    </Card>

    <!-- user dialogs -->

    <UserFormDialog
      v-model:visible="addUserDialogData.visible"
      :isSubmitting="addUserDialogData.isSubmitting"
      :roleOptions="rolesStore.hostRoleOptions"
      :disableNameFields="true"
      header="Invite User"
      @submit="onSubmitAddUser"
    />

    <UserFormDialog
      v-model:visible="editUserDialogData.visible"
      :isSubmitting="editUserDialogData.isSubmitting"
      :user="editUserDialogData.user"
      :roleOptions="rolesStore.hostRoleOptions"
      :disableNameFields="editUserDialogData.user && editUserDialogData.user.fname === null"
      @submit="onSubmitEditUser"
    />

    <ConfirmDialog
      v-model:visible="deleteUserDialogData.visible"
      header="Confirm Delete"
      :isSubmitting="deleteUserDialogData.isSubmitting"
      @confirm="onSubmitDeleteUser"
    >
      <div v-html="deleteUserDialogData.body" />
    </ConfirmDialog>

    <!-- program dialogs -->
    <ProgramFormDialog
      v-model:visible="addProgramDialogData.visible"
      :isSubmitting="addProgramDialogData.isSubmitting"
      :categoryOptions="categoriesStore.categoryOptions"
      :sensitiveCategoryOptions="categoriesStore.sensitiveCategoryOptions"
      :hostUserOptions="hostUserOptions"
      @submit="onSubmitAddProgram"
    />

    <ProgramFormDialog
      v-model:visible="editProgramDialogData.visible"
      :isSubmitting="editProgramDialogData.isSubmitting"
      :categoryOptions="categoriesStore.categoryOptions"
      :sensitiveCategoryOptions="categoriesStore.sensitiveCategoryOptions"
      :program="editProgramDialogData.program"
      :hostUserOptions="hostUserOptions"
      @submit="onSubmitEditProgram"
    />

    <ConfirmDialog
      v-model:visible="deleteProgramDialogData.visible"
      header="Confirm Delete"
      :isSubmitting="deleteProgramDialogData.isSubmitting"
      @confirm="onSubmitDeleteProgram"
    >
      <div v-html="deleteProgramDialogData.body" />
    </ConfirmDialog>
  </PageContainer>
</template>

<script>
import { mapStores } from 'pinia';
import { object, string } from 'yup';
import { INVALID_FORM_SUBMISSION_MESSAGE } from '@/utils/messages';
import {
  useMyUserStore,
  useUsersStore,
  useRolesStore,
  useProgramsStore,
  useCategoriesStore,
} from '@/stores';
import * as api from '@/api';
import ConfirmDialog from '@/components/confirmDialog';
import AlertDialog from '@/components/alertDialog';
import ProgramFormDialog from '@/components/programFormDialog';
import UserFormDialog from '@/components/userFormDialog';
import { parseMessageFromError } from '@/utils/errors';

const USER_STATUS_ACTIVE = 'Active';
const USER_STATUS_PENDING = 'Pending';

export default {
  components: {
    UserFormDialog,
    ConfirmDialog,
    ProgramFormDialog,
  },
  computed: {
    ...mapStores(
      useMyUserStore,
      useUsersStore,
      useRolesStore,
      useProgramsStore,
      useCategoriesStore,
    ),
    compiledUsers() {
      const users = this.myUserStore.myUser && this.myUserStore.myUser.organization_id
        ? this.usersStore.getUsersByOrganizationId(this.myUserStore.myUser.organization_id)
        : [];

      const { roles } = this.rolesStore;

      return users.map((user) => {
        const matchingRole = roles.find((role) => Number(role.id) === Number(user.role_id));

        const status = user.fname !== null
          ? USER_STATUS_ACTIVE
          : USER_STATUS_PENDING;
        const statusChipClass = user.fname !== null
          ? 'bg-green-400 text-white'
          : 'bg-blue-100 text-blue-500';

        // remove user emails that were created without intention of being able to log in
        const sanitizedEmail = user.email.includes('@email.com')
          ? '-'
          : user.email;

        return {
          ...user,
          role: matchingRole
            ? matchingRole.name
            : 'Unknown',
          status,
          statusChipClass,
          sanitizedEmail,
        };
      });
    },
    compiledPrograms() {
      const programs = this.myUserStore.myUser && this.myUserStore.myUser.organization_id
        ? this.programsStore.getProgramsByOrganizationId(this.myUserStore.myUser.organization_id)
        : [];

      return programs;
    },
    userRowOptionsDictionary() {
      return this.compiledUsers.reduce((acc, item) => {
        acc[item.id] = [
          {
            label: 'Edit',
            command: () => this.onEditUser(item),
          },
          {
            label: 'Delete',
            command: () => this.onDeleteUser(item),
          },
        ];

        return acc;
      }, {});
    },
    programRowOptionsDictionary() {
      return this.compiledPrograms.reduce((acc, item) => {
        acc[item.id] = [
          {
            label: 'Edit',
            command: () => this.onEditProgram(item),
          },
          {
            label: 'Delete',
            command: () => this.onDeleteProgram(item),
          },
        ];

        return acc;
      }, {});
    },
    hostUserOptions() {
      return this.myUserStore.myUser && this.myUserStore.myUser.organization_id
        ? this.usersStore.getUserOptionsByOrganizationId(this.myUserStore.myUser.organization_id)
        : [];
    },
    userToProgramDictionary() {
      const users = this.myUserStore.myUser && this.myUserStore.myUser.organization_id
        ? this.usersStore.getUsersByOrganizationId(this.myUserStore.myUser.organization_id)
        : [];

      return this.compiledPrograms.reduce((acc, program) => {
        const matchingUser = program.host_user_id
          ? users.find((user) => Number(user.id) === Number(program.host_user_id))
          : undefined;

        if (matchingUser) {
          matchingUser.fullName = `${matchingUser.fname} ${matchingUser.lname}`.trim();
        }

        acc[program.id] = matchingUser;

        return acc;
      }, {});
    },
  },
  data() {
    return {
      schema: object({
        name: string().required('Name is required'),
      }),
      organizationForm: {
        name: '',
      },
      organizationFormIsSubmitting: false,
      addUserDialogData: {
        visible: false,
        isSubmitting: false,
      },
      editUserDialogData: {
        visible: false,
        isSubmitting: false,
        user: null,
      },
      deleteUserDialogData: {
        visible: false,
        isSubmitting: false,
        user: null,
      },
      addProgramDialogData: {
        visible: false,
        isSubmitting: false,
      },
      editProgramDialogData: {
        visible: false,
        isSubmitting: false,
        program: null,
      },
      deleteProgramDialogData: {
        visible: false,
        isSubmitting: false,
        program: null,
      },
    };
  },
  mounted() {
    this.getOrganizationsUsers();
    this.rolesStore.getRoles();
    this.organizationForm = {
      name: this.myUserStore.myOrganization
        ? this.myUserStore.myOrganization.name
        : '',
    };
    this.getOrganizationsPrograms();
  },
  methods: {
    getOrganizationsUsers() {
      return this.usersStore.getUsers({
        organizationId: this.myUserStore.myOrganization
          ? this.myUserStore.myOrganization.id
          : undefined,
      });
    },
    getOrganizationsPrograms() {
      return this.programsStore.getPrograms({
        organizationId: this.myUserStore.myOrganization
          ? this.myUserStore.myOrganization.id
          : undefined,
      });
    },
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    async onSubmitOrganizationInfo() {
      try {
        this.organizationFormIsSubmitting = true;

        await api.updateOrganization({
          organizationId: this.myUserStore.myUser.organization_id,
          name: this.organizationForm.name,
        });
        await this.myUserStore.getMyUser();

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully updated organization',
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error updating organization.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.organizationFormIsSubmitting = false;
      }
    },
    async onSubmitAddUser({ userForm }) {
      try {
        this.addUserDialogData.isSubmitting = true;

        await api.inviteUser({
          organizationId: this.myUserStore.myOrganization.id,
          email: userForm.email,
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully invited user.',
        });

        this.addUserDialogData.visible = false;

        this.getOrganizationsUsers();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error inviting user.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.addUserDialogData.isSubmitting = false;
      }
    },
    onEditUser(user) {
      this.editUserDialogData = {
        ...this.editUserDialog,
        visible: true,
        user,
      };
    },
    async onSubmitEditUser({ user, userForm }) {
      try {
        this.editUserDialogData.isSubmitting = true;

        await api.updateUser({
          userId: user.id,
          firstName: userForm.firstName || '',
          lastName: userForm.lastName || '',
          email: userForm.email || '',
          roleId: userForm.roleId || undefined,
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully updated user.',
        });

        this.editUserDialogData.visible = false;
        this.editUserDialogData.user = null;

        this.getOrganizationsUsers();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error updating user.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.editUserDialogData.isSubmitting = false;
      }
    },
    onDeleteUser(user) {
      const userIsSelf = user.id === this.myUserStore.myUser.id;
      if (userIsSelf) {
        this.$dialog.open(AlertDialog, {
          data: {
            text: 'You cannot remove yourself from the organization.',
          },
          props: {
            header: 'Alert',
            modal: true,
            dismissableMask: true,
            closable: true,
          },
        });
        return;
      }

      const isLastActiveUser = user.status === USER_STATUS_ACTIVE && this.compiledUsers
        .filter((item) => item.status === USER_STATUS_ACTIVE).length === 1;
      if (isLastActiveUser) {
        this.$dialog.open(AlertDialog, {
          data: {
            text: 'You cannot remove the last active user of an organization.',
          },
          props: {
            header: 'Alert',
            modal: true,
            dismissableMask: true,
            closable: true,
          },
        });
        return;
      }
      // TODO - can't delete admin user

      this.deleteUserDialogData = {
        ...this.deleteUserDialogData,
        visible: true,
        body: `<p>Are you sure you want to delete this user: <strong>${user.email}</strong>?</p>
          <p>This action cannot be undone.</p>`,
        user,
      };
    },
    async onSubmitDeleteUser() {
      try {
        this.deleteUserDialogData.isSubmitting = true;

        await api.deleteUser(this.deleteUserDialogData.user.id);

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully deleted user.',
        });

        this.deleteUserDialogData.visible = false;
        this.deleteUserDialogData.user = null;

        this.getOrganizationsUsers();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error deleting user.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.deleteUserDialogData.isSubmitting = false;
      }
    },
    onEditProgram(program) {
      this.editProgramDialogData = {
        ...this.editProgramDialogData,
        visible: true,
        program,
      };
    },
    async onSubmitEditProgram({ program, programForm }) {
      try {
        this.editProgramDialogData.isSubmitting = true;

        const targetAgeRanges = programForm.targetAgeRanges.reduce((acc, item) => {
          acc[item] = true;

          return acc;
        }, {});

        await api.updateProgram({
          programId: program.id,
          name: programForm.name,
          description: programForm.showDescription,
          contactName: programForm.hostName,
          contactEmail: programForm.hostEmail,
          averageListeners: programForm.averageMonthlyDownloads,
          tapProgramId: programForm.tapProgramId,
          spreakerShowId: programForm.spreakerShowId,
          targetGender: programForm.targetGender,
          targetAgeRanges,
          adPreferences: programForm.adPreferences,
          categories: programForm.categories,
          blacklistedCategories: programForm.categoriesBlacklist,
          approvalBehavior: programForm.approvalBehavior,
          rssUrl: programForm.rssUrl,
          containsSensitiveContent: programForm.containsSensitiveContent,
          hostUserId: programForm.hostUserId,
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully updated podcast.',
        });

        this.editProgramDialogData.visible = false;
        this.editProgramDialogData.program = null;

        this.getOrganizationsPrograms();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error updating podcast.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.editProgramDialogData.isSubmitting = false;
      }
    },
    onDeleteProgram(program) {
      this.deleteProgramDialogData = {
        ...this.deleteProgramDialogData,
        visible: true,
        body: `<p>Are you sure you want to delete this program: <strong>${program.name}</strong>?</p>
          <p>This action cannot be undone.</p>`,
        program,
      };
    },
    async onSubmitDeleteProgram() {
      try {
        this.deleteProgramDialogData.isSubmitting = true;

        await api.deleteProgram(this.deleteProgramDialogData.program.id);

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully deleted podcast.',
        });

        this.deleteProgramDialogData.visible = false;
        this.deleteProgramDialogData.program = null;

        this.getOrganizationsPrograms();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error deleting podcast.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.deleteProgramDialogData.isSubmitting = false;
      }
    },
    async onSubmitAddProgram({ programForm }) {
      try {
        this.addProgramDialogData.isSubmitting = true;

        const targetAgeRanges = programForm.targetAgeRanges.reduce((acc, item) => {
          acc[item] = true;

          return acc;
        }, {});

        await api.createProgram({
          organizationId: this.myUserStore.myOrganization.id,
          name: programForm.name,
          description: programForm.showDescription,
          contactName: programForm.hostName,
          contactEmail: programForm.hostEmail,
          averageListeners: programForm.averageMonthlyDownloads,
          tapProgramId: programForm.tapProgramId,
          spreakerShowId: programForm.spreakerShowId,
          targetGender: programForm.targetGender,
          targetAgeRanges,
          adPreferences: programForm.adPreferences,
          categories: programForm.categories,
          blacklistedCategories: programForm.categoriesBlacklist,
          approvalBehavior: programForm.approvalBehavior,
          rssUrl: programForm.rssUrl,
          containsSensitiveContent: programForm.containsSensitiveContent,
          hostUserId: programForm.hostUserId,
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully added podcast',
        });
        this.getOrganizationsPrograms();

        this.addProgramDialogData.visible = false;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error adding podcast.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.addProgramDialogData.isSubmitting = false;
      }
    },
  },
};
</script>
