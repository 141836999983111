<template>
  <BaseDropdown
    ref="hostUserDropdown"
    :modelValue="hostUserId"
    @update:modelValue="$emit('update:hostUserId', $event)"
    fieldId="hostUserId"
    fieldName="hostUserId"
    fieldLabel="Host User"
    filter
    placeholder="Choose a user from your organization"
    :options="hostUserOptions"
    helperText="Choose the voice of the user you
      want to use for this podcast's advertising."
  >
    <template #footer>
      <div class="p-1">
        <Button
          class="w-full"
          severity="help"
          label="Add User"
          @click="onAddUserClick"
        />
      </div>
    </template>
  </BaseDropdown>
  <WarningMessage v-if="showNoHostUserWarning">
    You must add a host user to the podcast
    before your podcast can start receiving ad campaigns.
  </WarningMessage>
  <WarningMessage v-if="showHostUserHasNoVoiceWarning">
    The user you have chosen currently does not have a voice tied to their account.
    You must add a voide to the user in the organization page before your podcast can start
    receiving ad campaigns.
  </WarningMessage>

  <AddUserSidebar
    v-model:visible="isSidebarVisible"
    @selectUserid="onSelectUserId"
  />
</template>

<script>
import AddUserSidebar from './components/addUserSidebar';

export default {
  components: {
    AddUserSidebar,
  },
  props: {
    hostUserId: {
      validator: (prop) => typeof prop === 'number' || prop === null,
    },
    hostUserOptions: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    showNoHostUserWarning() {
      return !this.hostUserId;
    },
    showHostUserHasNoVoiceWarning() {
      if (!this.hostUserId) {
        return false;
      }
      const matchingUser = this.hostUserOptions.find((option) => option.value === this.hostUserId);
      return !!matchingUser && !matchingUser.user.voice_id;
    },
  },
  data() {
    return {
      isSidebarVisible: false,
    };
  },
  methods: {
    onAddUserClick() {
      this.isSidebarVisible = true;
      this.$refs.hostUserDropdown.$refs.dropdown.hide();
    },
    onSelectUserId(userId) {
      this.$emit('update:hostUserId', userId);
    },
  },
};
</script>
