<template>
  <div class="setup-container py-4 px-3">
    <ScrollContainer>
    <CreateOrganizationForm
      v-if="isCreateOrganizationFormVisible"
      :isSubmitting="isCreateOrganizationSubmitting"
      avatarIcon="pi pi-microphone"
      @submit="onSubmitOrganization"
      @cancel="onCancelOrganization"
    />

    <ProgramForm
      v-else-if="isCreateProgramFormVisible"
      :categoryOptions="categoriesStore.categoryOptions"
      :sensitiveCategoryOptions="categoriesStore.sensitiveCategoryOptions"
      cancelButtonLabel="Skip for now"
      :hostUserOptions="hostUserOptions"
      @submit="onSubmitProgram"
      @cancel="onCancelProgram"
    />

    <VoiceprintForm
      v-else-if="isCreateVoiceprintFormVisible"
      cancelButtonLabel="Skip"
      @complete="auditAccountSetup"
      @cancel="onCancelVoiceprint"
    />
    </ScrollContainer>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import {
  useMyUserStore,
  useProgramsStore,
  useCategoriesStore,
  useUsersStore,
} from '@/stores';
import CreateOrganizationForm from '@/components/createOrganizationForm';
import ProgramForm from '@/components/programForm';
import VoiceprintForm from '@/components/voiceprintForm';
import * as api from '@/api';
import { parseMessageFromError } from '@/utils/errors';
import { generateOrganizationSkipKey, generateProgramSkipKey, generateVoiceprintSkipKey } from '@/utils/localStorageKeys';
import { ROUTE_HOST_HOME } from '@/router/routes';
import ScrollContainer from '@/components/scrollContainer';

export default {
  components: {
    CreateOrganizationForm,
    ProgramForm,
    VoiceprintForm,
    ScrollContainer,
  },
  computed: {
    ...mapStores(useMyUserStore, useProgramsStore, useCategoriesStore, useUsersStore),
    hostUserOptions() {
      return this.myUserStore.myUser && this.myUserStore.myUser.organization_id
        ? this.usersStore.getUserOptionsByOrganizationId(this.myUserStore.myUser.organization_id)
        : [];
    },
  },
  data() {
    return {
      isCreateOrganizationFormVisible: false,
      isCreateOrganizationSubmitting: false,

      isCreateProgramFormVisible: false,
      isCreateProgramSubmitting: false,

      isCreateVoiceprintFormVisible: false,
      isCreateVoiceprintSubmitting: false,
    };
  },
  async mounted() {
    await this.auditAccountSetup();
    await this.getOrganizationsUsers();
  },
  methods: {
    async auditAccountSetup() {
      try {
        const organizationId = this.myUserStore.myOrganization.id;
        const organizationHasName = !!this.myUserStore.myOrganization
          && !!this.myUserStore.myOrganization.name;

        const orgnizationSkipKey = generateOrganizationSkipKey(organizationId);
        const skipOrganizationRename = !!localStorage.getItem(orgnizationSkipKey);

        if (skipOrganizationRename || organizationHasName) {
          this.isCreateOrganizationFormVisible = false;
        } else {
          this.isCreateOrganizationFormVisible = true;
          return;
        }

        this.isCreateOrganizationFormVisible = false;

        const programSkipKey = generateProgramSkipKey(organizationId);
        const skipProgramCreate = !!localStorage.getItem(programSkipKey);
        if (skipProgramCreate) {
          this.isCreateProgramFormVisible = false;
        } else {
          // check if user org has atleast 1 program
          await this.programsStore.getPrograms({
            organizationId,
          });
          const programs = this.programsStore
            .getProgramsByOrganizationId(organizationId);
          const organizationHasPrograms = programs.length > 0;

          // if not then show create program dialog
          if (!organizationHasPrograms) {
            this.isCreateProgramFormVisible = true;
            return;
          }

          this.isCreateProgramFormVisible = false;
        }

        // check if user has voiceprint
        const voiceprintSkipKey = generateVoiceprintSkipKey(this.myUserStore.userId);
        const skipVoiceprintCreate = !!localStorage.getItem(voiceprintSkipKey);
        if (skipVoiceprintCreate) {
          this.isCreateVoiceprintFormVisible = false;

          this.$router.push({
            name: ROUTE_HOST_HOME,
          });
        } else {
          // check if user has voiceprint
          const userHasVoiceprint = !!this.myUserStore.voiceId;

          // if not then show create voiceprint dialog
          if (!userHasVoiceprint) {
            this.isCreateVoiceprintFormVisible = true;
            return;
          }

          this.isCreateVoiceprintFormVisible = false;

          this.$router.push({
            name: ROUTE_HOST_HOME,
          });
        }
      } catch (error) {
        const message = parseMessageFromError(error, 'Error setting up account. Please refresh the page and try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onSubmitOrganization({
      organizationForm,
      invitedUsers,
    }) {
      try {
        this.isCreateOrganizationSubmitting = true;
        const organizationId = this.myUserStore.myOrganization.id;

        await api.updateOrganization({
          organizationId,
          name: organizationForm.name,
        });

        if (invitedUsers.length > 0) {
          try {
            await Promise.all(invitedUsers.map((user) => api.inviteUser({
              email: user.email,
              organizationId,
            })));
          } catch (error) {
            const message = parseMessageFromError(error, 'Organization created, but failed to invite users. Please visit the organization page and re-invite users.');

            this.$toast.add({
              severity: 'error',
              detail: message,
            });
          }
        }

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully created organization',
        });

        await this.myUserStore.getMyUser();
        this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error processing request. Please try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isCreateOrganizationSubmitting = false;
      }
    },
    async onCancelOrganization() {
      try {
        localStorage.setItem(generateOrganizationSkipKey(this.myUserStore.myOrganization.id), true);

        await this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error processing request. Please try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onSubmitOrganization_old({
      organizationForm,
      invitedUsers,
    }) {
      try {
        const userHasSkippedForm = organizationForm.name === '';

        this.isCreateOrganizationSubmitting = true;

        const res = await api.createOrganization({
          name: organizationForm.name,
        });
        const organizationId = res.organization_id;
        await api.updateUser({
          userId: this.myUserStore.myUser.id,
          organizationId,
        });

        if (!userHasSkippedForm) {
          this.$toast.add({
            severity: 'success',
            detail: 'Successfully created organization',
          });
        }

        if (invitedUsers.length > 0) {
          try {
            await Promise.all(invitedUsers.map((user) => api.inviteUser({
              email: user.email,
              organizationId,
            })));
          } catch (error) {
            const message = parseMessageFromError(error, 'Organization created, but failed to invite users. Please visit the organization page and re-invite users.');

            this.$toast.add({
              severity: 'error',
              detail: message,
            });
          }
        }

        await this.myUserStore.getMyUser();
        this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error processing request. Please try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isCreateOrganizationSubmitting = false;
      }
    },
    async onSubmitProgram({ programForm }) {
      try {
        this.isCreateProgramSubmitting = true;

        const targetAgeRanges = programForm.targetAgeRanges.reduce((acc, item) => {
          acc[item] = true;

          return acc;
        }, {});

        await api.createProgram({
          organizationId: this.myUserStore.myOrganization.id,
          name: programForm.name,
          description: programForm.showDescription,
          contactName: programForm.hostName,
          contactEmail: programForm.hostEmail,
          averageListeners: programForm.averageMonthlyDownloads,
          tapProgramId: programForm.tapProgramId,
          spreakerShowId: programForm.spreakerShowId,
          targetGender: programForm.targetGender,
          targetAgeRanges,
          adPreferences: programForm.adPreferences,
          categories: programForm.categories,
          blacklistedCategories: programForm.categoriesBlacklist,
          approvalBehavior: programForm.approvalBehavior,
          rssUrl: programForm.rssUrl,
          containsSensitiveContent: programForm.containsSensitiveContent,
          hostUserId: programForm.hostUserId,
        });

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully created podcast',
        });
        this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error adding podcast.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.isCreateProgramSubmitting = false;
      }
    },
    async onCancelProgram() {
      try {
        localStorage.setItem(generateProgramSkipKey(this.myUserStore.myOrganization.id), true);

        await this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error processing request. Please try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onCancelVoiceprint() {
      try {
        const userHasVoiceprint = !!this.myUserStore.voiceId;

        if (!userHasVoiceprint) {
          localStorage.setItem(generateVoiceprintSkipKey(this.myUserStore.userId), true);
        }

        await this.auditAccountSetup();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error processing request. Please try again.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async getOrganizationsUsers() {
      try {
        await this.usersStore.getUsers({
          organizationId: this.myUserStore.myOrganization
            ? this.myUserStore.myOrganization.id
            : undefined,
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error loading users.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.setup-container {
  height: calc(100vh - 67px);
  overflow-y: auto;
}
</style>
