<template>
  <BaseFieldContainer>
    <VeeField
      :name="fieldName"
      v-model="value"
      v-slot="{ errors, value: fieldValue, handleChange }"
    >
      <label
        v-if="fieldLabel"
        :for="fieldId"
        :class="{ 'text-red-400': errors.length > 0}"
        v-bind="fieldLabelAttrs"
      >
        {{ fieldLabel }}
      </label>
      <slot name="helperText">
        <small
          v-if="helperText"
          :id="`${fieldId}-help`"
          v-html="helperText"
        />
      </slot>
      <Dropdown
        ref="dropdown"
        :inputId="fieldId"
        :modelValue="fieldValue"
        @update:modelValue="handleChange"
        v-bind="{
          ...defaultAttrs,
          ...$attrs,
        }"
        :invalid="errors.length > 0"
        :aria-describedby="helperText ? `${fieldId}-help` : ''"
      >
        <template
          v-for="(_, name) in $slots"
          #[name]="scope"
        >
          <slot :name v-bind="scope ?? {}" />
        </template>
      </Dropdown>
      <small
        v-if="errors.length > 0"
        class="text-red-400"
      >
        {{ errors[0] }}
      </small>
    </VeeField>
  </BaseFieldContainer>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      validator: (prop) => typeof prop === 'string' || typeof prop === 'number',
    },
    fieldId: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    fieldLabel: {
      type: String,
    },
    helperText: {
      type: String,
    },
    fieldLabelAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    defaultAttrs() {
      return {
        optionLabel: 'label',
        optionValue: 'value',
      };
    },
  },
};
</script>
