<template>
  <PageContainer>
    <PageTitle>Profile</PageTitle>

    <UserSummaryCard
      :avatarLabel="myUserStore.userInitials"
      :username="myUserStore.fullname"
      :email="myUserStore.email"
      @logout="onLogout"
    />

    <!-- Profile Info -->
    <Card class="mt-4">
      <template #content>
        <VeeForm
          v-slot="{ handleSubmit }"
          :validation-schema="schema"
          as="div"
          @invalidSubmit="onInvalidSubmit"
        >
          <form
            @submit.prevent="handleSubmit($event, onSubmitProfileInfo)"
          >
            <h2 class="mt-0 text-xl">
              Profile Info
            </h2>
            <div class="grid">
              <div class="col-12 sm:col-6">
                <BaseInput
                  v-model="profileForm.firstName"
                  fieldId="firstName"
                  fieldName="firstName"
                  fieldLabel="First Name"
                />
              </div>
              <div class="col-12 sm:col-6">
                <BaseInput
                  v-model="profileForm.lastName"
                  fieldId="lastName"
                  fieldName="lastName"
                  fieldLabel="Last Name"
                />
              </div>
              <div class="col-12">
                <BaseInput
                  v-model="profileForm.email"
                  fieldId="email"
                  fieldName="email"
                  fieldLabel="Email"
                  type="email"
                />
              </div>
              <div class="col-12">
                <BaseTextarea
                  v-model="profileForm.description"
                  fieldId="publicBio"
                  fieldName="description"
                  fieldLabel="Host Professional Bio"
                  :helperText="PUBLIC_BIO_HELPER_TEXT"
                  :characterLimit="PUBLIC_BIO_CHARACTER_LIMIT"
                />
              </div>
              <Divider />
              <div class="col-12">
                <BaseTextarea
                  v-model="profileForm.personalBio"
                  fieldId="personalBio"
                  fieldName="personalBio"
                  fieldLabel="Host Personal Bio"
                  :characterLimit="PERSONAL_BIO_CHARACTER_LIMIT"
                >
                  <template #helperText>
                    <small
                      id="personalBio-help"
                      v-html="PERSONAL_BIO_HELPER_MARKUP"
                    />
                  </template>
                </BaseTextarea>
              </div>
              <!-- <Divider />
              <div class="col-12">
                <BaseTextarea
                  v-model="profileForm.showNotes"
                  fieldId="showNotes"
                  fieldName="showNotes"
                  fieldLabel="Show Notes"
                  helperText="Add a description of your podcast.
                    This will be used as context in your generated ads."
                />
              </div> -->
            </div>
            <div class="flex justify-content-end">
              <Button
                type="submit"
                :loading="profileFormIsSubmitting"
                label="Save"
              />
            </div>
          </form>
        </VeeForm>
      </template>
    </Card>

    <!-- Voiceprint -->
    <Card class="mt-4">
      <template #content>
        <div class="grid">
          <div class="col-12 sm:col sm:flex-grow-1">
            <h2 class="mt-0 text-xl">
              Your Voiceprint Eko
            </h2>
          </div>
          <div class="col-12 sm:col sm:flex-grow-0">
            <Button
              class="w-full justify-content-center"
              severity="success"
              @click="isManageVoiceprintDialogVisible = true"
            >
              <i class="mr-2 pi pi-microphone" />
              Manage
            </Button>
          </div>
        </div>

        <div class="mt-2">
          <VoiceprintCard
            :isLoading="myUserStore.myVoiceprintIsLoading"
            :src="myUserStore.myVoiceprint"
            :score="myUserStore.myVoiceprintScore"
            :isScoreLoading="myUserStore.myVoiceprintScoreIsLoading"
          >
            <template #noVoiceprint>
              <div class="py-2">
                No voiceprint found.
                <Button
                  class="p-0"
                  link
                  size="small"
                  @click="isManageVoiceprintDialogVisible = true"
                >
                  Create
                </Button>
                your first Voiceprint Eko.
              </div>
            </template>
          </VoiceprintCard>
        </div>

        <Divider class="mt-3 mb-2"/>

        <h2 class="text-lg">
          Your Voice Samples
        </h2>

        <p class="text-sm">
          These voice samples along with your user profile are used to
          create your Voiceprint Eko.<br /><br />
          We recommend including 1 to 3 samples that total to at
          least 90 seconds to provide the most accurate result.
          The samples should be as clear as possible with no background noise.
          If available, use existing ads that you have recorded.
        </p>

        <!-- Voice samples -->

        <div
          v-if="myUserStore.myVoiceSamplesAreLoading"
          class="mt-2"
        >
          <Skeleton
            v-for="index in 2"
            :key="index"
            class="mt-3"
            height="70px"
          />
        </div>

        <ul
          v-else
          class="list-none px-0"
        >
          <li v-if="myUserStore.myVoiceSamples.length === 0">
            No voice samples found.
            <Button
              class="p-0"
              link
              size="small"
              @click="isManageVoiceprintDialogVisible = true"
            >
              Manage
            </Button>
            your voiceprint eko to upload sample voice files.
          </li>
          <li
            class="mt-3"
            v-for="(sample) in myUserStore.myVoiceSamples"
            :key="sample.id"
          >
            <VoiceSampleCard
              :sample="sample"
              @deleteSample="onRemoveSampleClick"
            />
          </li>
        </ul>
      </template>
    </Card>

    <ManageVoiceprintDialog v-model:visible="isManageVoiceprintDialogVisible"/>

    <ConfirmDialog
      v-model:visible="deleteVoiceSampleDialog.visible"
      header="Confirm Delete"
      :isSubmitting="deleteVoiceSampleDialog.isSubmitting"
      @confirm="onRemoveVoiceSampleConfirm"
    >
      <p>
        Are you sure you want to delete this voice sample?
      </p>
      <p>
        Upon deletion, your Voiceprint Eko will be updated
        with the remaining samples.
      </p>
    </ConfirmDialog>
  </PageContainer>
</template>

<script>
import { mapStores } from 'pinia';
import { object, string } from 'yup';
import ManageVoiceprintDialog from '@/components/manageVoiceprintDialog';
import { useAuthStore, useMyUserStore } from '@/stores';
import ConfirmDialog from '@/components/confirmDialog';
import VoiceSampleCard from '@/components/voiceSampleCard';
import VoiceprintCard from '@/components/voiceprintCard';
import * as api from '@/api';
import { INVALID_FORM_SUBMISSION_MESSAGE, PUBLIC_BIO_HELPER_TEXT, PERSONAL_BIO_HELPER_MARKUP } from '@/utils/messages';
import { ROUTE_LOGIN } from '@/router/routes';
import UserSummaryCard from '@/components/userSummaryCard';
import { parseMessageFromError } from '@/utils/errors';
import { PERSONAL_BIO_CHARACTER_LIMIT, PUBLIC_BIO_CHARACTER_LIMIT } from '@/constants';

export default {
  components: {
    ManageVoiceprintDialog,
    ConfirmDialog,
    VoiceSampleCard,
    VoiceprintCard,
    UserSummaryCard,
  },
  computed: {
    ...mapStores(useMyUserStore, useAuthStore),
  },
  data() {
    return {
      PUBLIC_BIO_HELPER_TEXT,
      PERSONAL_BIO_HELPER_MARKUP,
      PUBLIC_BIO_CHARACTER_LIMIT,
      PERSONAL_BIO_CHARACTER_LIMIT,
      schema: object({
        email: string().email().required('Email is required'),
        description: string().nullable().max(PUBLIC_BIO_CHARACTER_LIMIT, `Host professional bio can be a max of ${PUBLIC_BIO_CHARACTER_LIMIT} characters.`),
        personalBio: string().nullable().max(PERSONAL_BIO_CHARACTER_LIMIT, `Host personal bio can be a max of ${PERSONAL_BIO_CHARACTER_LIMIT} characters.`),
      }),
      profileForm: {
        firstName: '',
        lastName: '',
        email: '',
        description: '',
        personalBio: '',
        showNotes: '',
      },
      profileFormIsSubmitting: false,
      deleteVoiceSampleDialog: {
        visible: false,
        isSubmitting: false,
        voiceSample: null,
      },
      isManageVoiceprintDialogVisible: false,
    };
  },
  async mounted() {
    this.profileForm = {
      firstName: this.myUserStore.myUser
        ? this.myUserStore.myUser.fname
        : '',
      lastName: this.myUserStore.myUser
        ? this.myUserStore.myUser.lname
        : '',
      email: this.myUserStore.email,
      description: this.myUserStore.myUser
        ? this.myUserStore.myUser.description
        : '',
      personalBio: this.myUserStore.myUser
        ? this.myUserStore.myUser.personal_bio
        : '',
      showNotes: this.myUserStore.myUser
        ? this.myUserStore.myUser.show_notes
        : '',
    };

    await this.getMyVoiceSamples();

    try {
      await this.getMyVoiceprint();
    } catch (error) {
      const message = parseMessageFromError(error, 'Error generating Voiceprint Eko.');

      this.$toast.add({
        severity: 'error',
        detail: message,
      });
    }

    await this.getMyVoiceprintScore();
  },
  methods: {
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    async getMyVoiceSamples() {
      try {
        await this.myUserStore.getMyVoiceSamples();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error loading voice samples.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async getMyVoiceprint() {
      try {
        if (this.myUserStore.voiceId && !this.myUserStore.myVoiceprint) {
          await this.myUserStore.getMyVoiceprint();
        }
      } catch (error) {
        const message = parseMessageFromError(error, 'Error generating Voiceprint Eko.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async getMyVoiceprintScore() {
      try {
        await this.myUserStore.getMyVoiceprintScore();
      } catch (error) {
        const message = parseMessageFromError(error, 'Error loading Voiceprint Eko score.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onSubmitProfileInfo() {
      try {
        this.profileFormIsSubmitting = true;

        await api.updateUser({
          userId: this.myUserStore.userId,
          firstName: this.profileForm.firstName,
          lastName: this.profileForm.lastName,
          email: this.profileForm.email,
          description: this.profileForm.description,
          personalBio: this.profileForm.personalBio,
          showNotes: this.profileForm.showNotes,
        });
        await this.myUserStore.getMyUser();

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully updated user',
        });
      } catch (error) {
        const message = parseMessageFromError(error, 'Error updating user.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.profileFormIsSubmitting = false;
      }
    },
    onRemoveSampleClick(voiceSample) {
      this.deleteVoiceSampleDialog = {
        ...this.deleteVoiceSampleDialog,
        visible: true,
        voiceSample,
      };
    },
    async onRemoveVoiceSampleConfirm() {
      try {
        this.deleteVoiceSampleDialog.isSubmitting = true;

        await api.deleteAudioSample(this.deleteVoiceSampleDialog.voiceSample.id);
        await this.getMyVoiceSamples();

        if (this.myUserStore.voiceId) {
          await this.myUserStore.getMyVoiceSettings(this.myUserStore.userId);
          await api.updateUserVoicePrint({
            userId: this.myUserStore.userId,
            sampleIds: this.myUserStore.myVoiceSamples.map((item) => item.id),
            accent: this.myUserStore.voiceAccent,
            gender: this.myUserStore.voiceGender,
          });
          await this.myUserStore.getMyVoiceprint();
        }

        this.$toast.add({
          severity: 'success',
          detail: 'Successfully deleted voice sample.',
        });

        this.deleteVoiceSampleDialog.visible = false;
        this.deleteVoiceSampleDialog.voiceSample = null;
      } catch (error) {
        const message = parseMessageFromError(error, 'Error deleting voice sample.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      } finally {
        this.deleteVoiceSampleDialog.isSubmitting = false;
      }
    },
    onLogout() {
      this.authStore.logout();
      this.$router.push({ name: ROUTE_LOGIN });
    },
  },
};
</script>
