export const INVALID_FORM_SUBMISSION_MESSAGE = 'There are errors in your form submission';

export const PERSONAL_BIO_HELPER_MARKUP = `
  <p>
    Write a personal bio describing yourself.
    We recommend including specific information
    that you may relay in your show or on social
    media that will be used to craft personalized ads.
  </p>
  <p>
    For example, "I have three kids, a son and two daughters,
    and have been married for 20 years. When not producing my show,
    I love to work out and ski. My dog is a golden doodle named Georgia.
  </p>
`;

export const PUBLIC_BIO_HELPER_TEXT = 'Write a professional bio about yourself. Include things like work history, education, skills and expertise. Such as something you might have on LinkedIn.';
